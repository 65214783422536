<template>
      <div>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <div class="font-weight-black course-prop-label">Tên bài tập</div>
            <ValidationProvider name="name" rules="required" v-slot="{ errors }">
              <v-text-field
                  v-model="exercise.title"
                  placeholder="Nhập tên bài hoc"
                  dense
                  outlined
              ></v-text-field>
              <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <div class="font-weight-black course-prop-label">Mô tả ngắn gọn</div>
            <ValidationProvider
                name="description"
                rules="required|minmax:0,255"
                v-slot="{ errors }"
            >
              <v-textarea
                  outlined
                  v-model="exercise.description"
                  value="Nhập mô tả ngắn gọn về khóa học"
              ></v-textarea>
              <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <div class="font-weight-black course-prop-label">Chọn màu</div>
            <ValidationProvider
                name="description"
                rules="required"
                v-slot="{ errors }"
            >
              <v-color-picker v-model="exercise.color"></v-color-picker>
              <v-sheet
                  dark
                  class="pa-4"
                  width="200px"
              >
                <pre class="text-center font-show-color">{{ showColor }}</pre>
              </v-sheet>
              <span class="validate-error text-danger text-danger">
              {{ errors[0] }}
            </span>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row class="pa-2 justify-center d-flex mb-10">
          <v-btn
              type="submit"
              v-if="!isLoading"
              width="150"
              depressed
              @click="handleUpload"
              color="success"
          >Cập Nhật
          </v-btn
          >
          <v-btn
              v-if="isLoading"
              loading
              width="150"
              depressed
              @click="handleUpload"
              color="success"
          >Cập Nhật
          </v-btn
          >
        </v-row>
      </div>

</template>

<script>
// import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import {mapState, mapGetters} from "vuex";
import CkContent from "@/components/ck-content";
import {
  GET_DETAIL_EXERCISE,
  SAVE_EXERCISE,
  UPDATE_EXERCISE
} from "@/store/exercise-toeic.module";

import mixin from "@/store/mixin";

export default {
  mixins: [mixin],
  props: ["slug"],
  data: function () {
    return {
      // exercise: {
      //   title: '',
      //   description:'',
      //   color: '',
      // },
      value: "123456789",
      label: "",
      readonly: false,
      disabled: false,
      outlined: true,
      errors: {},
      isLoading: false,
    };
  },
  components: {
    CkContent,
  },
  computed: {
    ...mapGetters({
      exercise: 'getDetailExercise'
    }),
    ...mapState({

    }),
    showColor () {
      if (typeof this.exercise.color === 'string') return this.exercise.color

      return JSON.stringify(Object.keys(this.color).reduce((color, key) => {
        color[key] = Number(this.exercise.color[key].toFixed(2))
        return color
      }, {}), null, 2)
    },
  },
  created() {
    console.log(this.exercise)
  },
  watch: {
    exercise: {
      deep: true,
      immediate: true,
      handler(self) {
        if(self.title){
          self.slug = this.sanitizeTitle(self.title);
        }
      },
    },
  },
  mounted() {
    if (this.slug != null) {
      this.$store.dispatch(GET_DETAIL_EXERCISE, {
        slug: this.slug,
      });
    }
    if (this.slug == null) {
      this.$store.dispatch(GET_DETAIL_EXERCISE, {});
      this.exercise.slug = this.sanitizeTitle(this.exercise.title);
    }
  },
  methods: {
    sanitizeTitle(title) {
      var str = title.toLowerCase();
      str = str.trimStart();
      str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
      str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
      str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
      str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
      str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
      str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
      str = str.replace(/đ/g, "d");
      // Some system encode vietnamese combining accent as individual utf-8 characters
      str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng
      str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
      // Trim the last whitespace
      str = str.replace(/\s*$/g, '');
      str = str.replace(/\?|\(|\)|\/|!|@|#|\$|%|\^|&|:|,|\||\]|\[|{|}|\\|-|/g,'');
      // Change whitespace to "-"
      str = str.replace(/\s+/g, '-');

      return str;
    },
    handleUpload() {
      // add event loading

      this.isLoading = true;
      //update course
      if (this.slug != null) {
        this.$store
            .dispatch(UPDATE_EXERCISE, {
              exercise: this.exercise,
            })
            .then(() => {
              this.$router.push("/exercise/list");
            });
      }
      //save course
      if (this.slug == null) {
        this.$store
            .dispatch(SAVE_EXERCISE, {
              exercise: this.exercise,
            })
            .then(() => {
              this.isLoading = false;
              this.$router.push("/exercise/list");
            });
      }
    }
  },
};
</script>

<style>
.font-show-color{
  color: #ffffff;
}
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0px !important;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}

form {
  font-size: 14px;
}

.course-prop-label {
  margin-bottom: 5px;
}
</style>
